import React from "react";

export default ({ color }) => (
  <svg width="34" height="36" viewBox="0 0 34 36">
    <g clipPath="url(#clip-check)">
      <path
        d="M11.5481 30.8955L0.498142 19.1955C-0.165722 18.4926 -0.165722 17.3529 0.498142 16.6499L2.90225 14.1043C3.56611 13.4014 4.64256 13.4014 5.30642 14.1043L12.7502 21.9859L28.694 5.10433C29.3579 4.40142 30.4343 4.40142 31.0982 5.10433L33.5023 7.64993C34.1662 8.35284 34.1662 9.49254 33.5023 10.1955L13.9523 30.8956C13.2884 31.5985 12.212 31.5985 11.5481 30.8955Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip-check">
        <rect width="34" height="36" fill={color} />
      </clipPath>
    </defs>
  </svg>
);
